import { configureStore } from '@reduxjs/toolkit';
import { authApi } from 'features/auth/authSlice';


// Combine all reducers and API slices
const rootReducer = {
    [authApi.reducerPath]: authApi.reducer,
};

// Combine all middlewares
const middleware = (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware);


export const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "components/card"; // Assuming you have a Card component for styling

const API_BASE_URL = process.env.REACT_APP_API_URL;
// const API_BASE_URL = 'http://localhost:5306'




const FileUploader = () => {
  const [files, setFiles] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [runningFile, setRunningFile] = useState(null); // Track the currently running file

  const handleFileChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles(uploadedFiles);
  };
  const handleDeleteFile = async (file) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/scraper/delete-file`,
        {
          params: {
            fileName: file.fileName, // Pass the filename as a query parameter
          },
        }
      );

      if (response.data.status === "1") {
        alert("File deleted successfully.");
        fetchFileDetails(); // Refresh the file list after deletion
      } else {
        alert("Failed to delete file: " + response.data.message);
      }
    } catch (error) {
      console.error("Error deleting file:", error.message);
      alert("Error deleting file. Please try again.");
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("data_file", file);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/scraper/upload-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("File uploaded:", response.data);
      fetchFileDetails(); // Fetch the file details after successful upload
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file. Please try again.");
    }
  };

  const fetchFileDetails = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/scraper/list-files`
      );
      if (response.data.status === "1") {
        setFileDetails(response.data.fileDetails); // Set the file details in state
      } else {
        console.error("Error fetching file details:", response.data.message);
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching file details:", error);
      alert("Error fetching file details. Please try again.");
    }
  };

  useEffect(() => {
    fetchFileDetails(); // Call the function to fetch file details when the component mounts
  }, []); // The empty dependency array ensures this runs only once when the component mounts

  // Function to start appending/scraping for a file
  useEffect(() => {
    // Fetch the currently running file from the backend when the page loads
    const fetchRunningFile = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/scraper/get-current-running-file`
        );
        const { currentlyRunningFile } = response.data; // Corrected to use currentlyRunningFile
  
        if (currentlyRunningFile) {
          setRunningFile(currentlyRunningFile); // Set the currently running file in state
        } else {
          setRunningFile(null); // No file is being appended
        }
      } catch (error) {
        console.error("Error fetching current running file:", error.message);
      }
    };
  
    fetchRunningFile(); // Fetch when the component mounts
  }, []); // The empty dependency array ensures this runs only once when the component mounts
  

  const handleStartScraping = async (file) => {
    if (runningFile) {
      alert(
        `File ${runningFile} is already being appended. Please stop it first.`
      );
      return;
    }

    const data = { fileName: file.fileName };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/scraper/start-scraping`,
        data,
        { headers: { "Content-Type": "application/json" } }
      );

      alert(`Scraping started successfully for ${file.fileName}`);
      setRunningFile(file.fileName); // Set the currently running file in state
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      alert(errorMessage);
    }
  };

  const handleStopScraping = async () => {
    if (!runningFile) {
      alert("No scraping process is currently running.");
      return;
    }

    const data = { fileName: runningFile }; // Use the currently running file

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/scraper/stop-scraping`,
        data,
        { headers: { "Content-Type": "application/json" } }
      );

      alert(response.data.message);
      setRunningFile(null); // Clear the running file after stopping
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      alert(errorMessage);
    }
  };

  const downloadFile2 = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/scraper/downlaod-template`,
        {
          responseType: "blob", // Set the response type to blob for downloading files
        }
      );

      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      //   link.setAttribute("download"); // Use the file name for the downloaded file
      //   document.body.appendChild(link);
      link.click();
      link.remove();

      console.log("Download initiated for template");
      alert("Download initiated. Please check your browser's download folder."); // Alert for download initiation
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      console.error("Error downloading:", errorMessage);
      alert(errorMessage); // Show the error message from the API
    }
  };
  const downloadFile = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/scraper/downlaod-template`,
        {
          responseType: "blob", // Set the response type to blob for downloading files
        }
      );
      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx"); // Use the file name for the downloaded file
      document.body.appendChild(link);
      link.click();
      link.remove();

      console.log("Download started:", response.data);
      alert("Download initiated. Please check your browser's download folder."); // Alert for download initiation
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      console.error("Error downloading:", errorMessage);
      alert(errorMessage); // Show the error message from the API
    }
  };

  const handleDownloadFile = async (file) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/scraper/downlaod-file`,
        {
          params: {
            fileName: file.fileName, // Send the filename as a query parameter
          },
          responseType: "blob", // Set the response type to blob for downloading files
        }
      );

      // Create a URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.fileName); // Use the file name for the downloaded file
      document.body.appendChild(link);
      link.click();
      link.remove();

      console.log("Download started:", response.data);
      alert("Download initiated. Please check your browser's download folder."); // Alert for download initiation
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      console.error("Error downloading:", errorMessage);
      alert(errorMessage); // Show the error message from the API
    }
  };

  return (
    <Card extra={"w-full h-full p-6 bg-white shadow-lg"}>
      <h2 className="text-2xl font-bold text-navy-400">Upload a File</h2>
      <input
        type="file"
        accept=".xls,.xlsx,.csv"
        onChange={handleFileChange}
        multiple
        className="mt-4 rounded border border-gray-300 p-2"
      />
      <div className="flex flex-row gap-x-3">
        <button
          className="mt-4 w-1/3 rounded-md bg-blue-500 p-2 text-white hover:bg-blue-600" // Ensure full width for consistent sizing
          onClick={() => files.forEach(uploadFile)} // Upload each selected file
        >
          Upload Files
        </button>

        <button
          className="mt-4 w-1/3 rounded-md bg-blue-500 p-2 text-white hover:bg-blue-600" // Ensure full width for consistent sizing
          onClick={handleStopScraping} // Stop scraping button
        >
          Stop Appending
        </button>

        <button
          className="mt-4 w-1/3 rounded-md bg-blue-500 p-2 text-white hover:bg-blue-600" // Ensure full width for consistent sizing
          onClick={() => downloadFile()} // Use the new download function
        >
          Download Template
        </button>
      </div>

      {/* Display current appending file status */}
      <div className="my-4 bg-gray-300 p-1">
        <h2 className=" font-semibold">Current Status</h2>
        {runningFile ? (
          <p className="text-green-600">Currently appending: {runningFile}</p>
        ) : (
          <p className="text-red-600">No files are currently being appended.</p>
        )}
      </div>

      <h2 className="mt-6 font-bold underline">File Details</h2>
      <table className="mt-4 w-full border border-gray-300">
        <thead>
          <tr className="bg-gray-500 ">
            <th className="border p-2">File Name</th>
            <th className="border p-2">Total Rows</th>
            <th className="border p-2">Appended Rows</th>
            <th className="border p-2">Appended Percentage</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {fileDetails.map((file, index) => (
            <tr key={index} className="hover:bg-gray-400">
              <td className="border p-2">{file.fileName}</td>
              <td className="border p-2">{file.totalRows}</td>
              <td className="border p-2">{file.scrapedRows}</td>
              <td className="border p-2">{file.scrapedPercentage}</td>
              <td className="border p-2">
                <button
                  className="m-1 rounded-md bg-blue-500 p-1 text-white hover:bg-blue-600"
                  onClick={() => handleStartScraping(file)}
                  disabled={!!runningFile} // Disable if a file is already running
                >
                  Start Appending
                </button>
                <button
                  className="m-1 rounded-md bg-yellow-500 p-1 text-white hover:bg-yellow-600"
                  onClick={() => handleDownloadFile(file)} // Pass the file object to download
                >
                  Download
                </button>
                <button
                  className="m-1 rounded-md bg-red-500 p-1 text-white hover:bg-red-600"
                  onClick={() => handleDeleteFile(file)}
                >
                  Delete
                </button>{" "}
                {/* Add Delete button */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default FileUploader;

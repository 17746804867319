import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import { AuthProvider } from "views/auth/auth-logic/AuthContext";
import {store} from './state/index';

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
    </Provider>
  
);

// src/features/auth/authApiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_API_URL; // Make sure this is correctly set in your .env file

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/admin` }), // Base URL for your API
    endpoints: (builder) => ({
        signup: builder.mutation({
            query: (signupData) => ({
                url: '/signup',
                method: 'POST',
                body: signupData, // The signup data being sent in the request body
            }),
        }),
        login: builder.mutation({
            query: (loginData) => ({
                url: '/login',
                method: 'POST',
                body: loginData, // The login data being sent in the request body
            }),
        }),
    }),

});



export const { useSignupMutation, useLoginMutation } = authApi; // Export the hook for the mutation
